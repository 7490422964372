import { createApi } from '@reduxjs/toolkit/query/react'

import { staggeredBaseQueryWithBailOut } from '../libs/services'
import { unversionedApiUrl } from './common'
import { ServiceBaseUrl } from './serviceApi'
import { ServicesApiTag, ServicesReducerPath } from './constants'
import qs from 'qs'
import { getSystemHeaders } from '../libs/system'
import {
	ContactLensesOptions,
	ContactLensesOptionsParams,
	ContactLensesOptionWithName,
	UpriseLensTypeId,
} from '../model/contactLenses'
import { ContactLensesBase, ContactLensesCatalogData } from '../model/exam'
import { useDebounce } from '../hooks/useDebounce'

export const contactLensesApi = createApi({
	reducerPath: ServicesReducerPath.ContactLensesApi,
	tagTypes: [ServicesApiTag.ContactLenses],
	baseQuery: staggeredBaseQueryWithBailOut(unversionedApiUrl),
	endpoints: builder => ({
		get: builder.query<ContactLensesCatalogData[], string | undefined>({
			query: query => ({
				url: `${ServiceBaseUrl.ContactLenses}${query ? `?q=${query}` : ''}`,
				headers: getSystemHeaders(),
			}),
			providesTags: [ServicesApiTag.ContactLenses],
			keepUnusedDataFor: 0,
		}),

		getOptions: builder.query<ContactLensesOptions, ContactLensesOptionsParams>(
			{
				query: query => ({
					url: `${ServiceBaseUrl.ContactLenses}/options?${qs.stringify(query)}`,
					headers: getSystemHeaders(),
				}),
			},
		),

		getUpriseCustomLensTypes: builder.query<
			ContactLensesOptionWithName[],
			void
		>({
			query: query => ({
				url: `${ServiceBaseUrl.ContactLenses}/uprise/custom-lens-types`,
				headers: getSystemHeaders(),
			}),
		}),

		getUpriseCustomLensTypeMaterials: builder.query<
			ContactLensesOptionWithName[],
			UpriseLensTypeId
		>({
			query: lensTypeId => ({
				url: `${ServiceBaseUrl.ContactLenses}/uprise/custom-lens-types/${lensTypeId}/materials`,
				headers: getSystemHeaders(),
			}),
		}),

		getById: builder.query<ContactLensesCatalogData, string>({
			query: contactLensesId => ({
				url: `${ServiceBaseUrl.ContactLenses}/${contactLensesId}`,
				headers: getSystemHeaders(),
			}),
		}),

		createContactLens: builder.mutation<void, ContactLensesBase>({
			query: body => ({
				url: `${ServiceBaseUrl.ContactLenses}`,
				method: 'POST',
				body: JSON.stringify(body),
			}),
			invalidatesTags: [ServicesApiTag.ContactLenses],
		}),

        updateContactLens: builder.mutation<void, ContactLensesCatalogData>({
            query: body => ({
                url: `${ServiceBaseUrl.ContactLenses}/${body._id}`,
                method: 'PUT',
                body: {
                    ...body,
                    additionalParams: body.additionalParams ? JSON.stringify(body.additionalParams) : ''
                },
            }),
            invalidatesTags: [ServicesApiTag.ContactLenses],
        }),

        enableContactLens: builder.mutation<void, { id: string; value: { enabled: boolean; additionalParams?: any } }>({
            query: ({ id, value }) => ({
                url: `${ServiceBaseUrl.ContactLenses}/${id}`,
                method: 'PATCH',
                body: {
                    path: `enabled`,
                    value: value.enabled,
                    additionalParams: value.additionalParams ? JSON.stringify(value.additionalParams) : ''
                },
            }),
            invalidatesTags: [ServicesApiTag.ContactLenses],
        }),
	}),
})

const useGetDebounced = (query: string) => {
	const debouncedQuery = useDebounce(query, 500)

	return contactLensesApi.useGetQuery(debouncedQuery, {
		refetchOnMountOrArgChange: true,
	})
}

export const useGetOptionsQuery = (
	params: ContactLensesOptionsParams,
	skip?: boolean,
) => {
	return contactLensesApi.useGetOptionsQuery(params, { skip })
}

export const useGetUpriseCustomLensTypes = (skip?: boolean) => {
	return contactLensesApi.useGetUpriseCustomLensTypesQuery(undefined, { skip })
}

export const useGetUpriseCustomLensTypeMaterials = (
	lensTypeId?: UpriseLensTypeId,
	skip?: boolean,
) => {
	return contactLensesApi.useGetUpriseCustomLensTypeMaterialsQuery(
		lensTypeId || '',
		{
			skip: skip || !lensTypeId,
		},
	)
}

export const useLazyGetOptionsQuery = () => {
	return contactLensesApi.useLazyGetOptionsQuery()
}

export const useContactLensesApi = {
	getDebounced: useGetDebounced,
}

export const {
	useCreateContactLensMutation,
	useUpdateContactLensMutation,
	useEnableContactLensMutation,
} = contactLensesApi
